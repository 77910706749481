<template>
  <a-drawer title="個別選択" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }" @close="onClose">

    <a-form layout="horizontal" autocomplete="off">
      <a-tree v-model:checkedKeys="checkedKeys" default-expand-all checkable
        :height="400" :tree-data="objectList">
      </a-tree>

      <br>

      <a-space wrap>
        <!-- TODO:ボタンの左寄せ -->
        <a-button @click="onDeselect">
          選択解除
        </a-button>
        <!-- TODO:ボタンの右寄せ -->
        <a-button @click="onDownload">
          DL
        </a-button>
        <a-button>
          <a-popconfirm title="本当に削除しますか？" @confirm="onDelete">
            <a>削除</a>
          </a-popconfirm>
        </a-button>
      </a-space>
      <br>
      <br>
      <!-- TODO:ボタンの右寄せ？ -->
      <a-button @click="onClose">キャンセル</a-button>

    </a-form>
  </a-drawer>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { notification } from 'ant-design-vue';
import useDeleteRecord from '@/composable/useDeleteRecord'
import useDownloadDataset from '@/composable/useDownloadDataset';
import useAsyncZipSaver from '@/composable/useAsyncZipSaver'

export default defineComponent({
  components: {
  },
  setup() {
    const visible = ref(false);
    const objectList = ref([])
    const checkedKeys = ref([])
    const allFiles = {}

    const showDrawer = (files) => {
      Array.from(files).map(
        (file) => {
          const key = file.key;
          const treeNode = {
            title: key,
            key,
          };
          allFiles[key] = file
          objectList.value.push(treeNode);
          checkedKeys.value.push(key)
        }
      )
      visible.value = true;
    };

    const onClose = () => {
      objectList.value.splice(0)
      checkedKeys.value.splice(0)
      visible.value = false;
    };

    const onDeselect = () => {
      checkedKeys.value.splice(0);
    }

    const onDownload = () => {
      notifyDownloadStart()

      const toDownloadFiles = []
      Array.from(checkedKeys.value).map(
        (key) => {
          toDownloadFiles.push(allFiles[key])
        }
      )
      if (toDownloadFiles.length) {
        useDownloadDataset(toDownloadFiles)
          .then((contents) => {
            useAsyncZipSaver(toDownloadFiles, contents)
          })
          .catch((error) => {
            notifyGeneralError(error)
          })
      } else {
        notifyNoDownloadableData()
      }
    }

    const onDelete = () => {
      const toDeleteFiles = []
      Array.from(checkedKeys.value).map(
        (key) => {
          toDeleteFiles.push(allFiles[key])
        }
      )
      useDeleteRecord(toDeleteFiles)
        .then(() => {
          notifyDeleted()

          Array.from(checkedKeys.value).map(
            (key) => {
              objectList.value.forEach((object, index) => {
                if (key == object.key) {
                  objectList.value.splice(index, 1)
                }
              })
            }
          )
          checkedKeys.value.splice(0)
        })
        .catch(error => {
          notifyGeneralError(error);
        });
    }

    const notifyDownloadStart = () => {
      notification.open({
        message: 'ダウンロードを開始します',
        duration: 2,
      });
    };

    const notifyDeleted = () => {
      notification.open({
        message: '選択したファイルを削除しました',
        duration: 2,
      });
    };

    const notifyNoDownloadableData = () => {
      notification.open({
        message: 'ダウンロード可能なデータが見つかりませんでした',
        duration: 2,
      });
    };

    const notifyGeneralError = (error) => {
      console.log(error)
      notification.open({
        message: 'エラーが発生しました',
        duration: 10,
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
          color: '#FF0000',
        },
      });
    };

    return {
      visible,
      showDrawer,
      onClose,

      onDeselect,
      onDownload,
      onDelete,

      checkedKeys,
      objectList,
    };
  },
  methods: {
  },
  data() {
    return {
    }
  },
});
</script>
