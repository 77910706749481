import { Storage } from 'aws-amplify'

async function useDeleteRecord(files) {

  return Promise.all(
      Array.from(files).map(
        (file) =>
            new Promise((resolve, reject) => {
                try {
                    Storage.remove(file.key)
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        )
    );
}

export default useDeleteRecord;