<template>
    <!-- <br> -->
    <!-- <a-typography-text>T.B.D. DatePicker表示するとエラーになる</a-typography-text> -->
    <!-- <a-date-picker format="YYYY-MM-DD" @change="onChange" /> -->
    <!-- <a-form autocomplete="off"> -->
    <a-form-item label=" " name="start_date"
        :rules="[{ required: true, message: 'データ収集開始日は必須項目です', validator: checkDate, trigger: 'change' }]">
        <a-input v-model:value="start_date" allowClear placeholder="YYYYMMDD" style="width: 120px"/>
    </a-form-item>
    <!-- </a-form> -->
</template>

<script>
import { defineComponent, inject } from 'vue';
// import moment from 'moment';

export default defineComponent({
    setup() {
        // 上位コンポーネントから共有された変数
        const start_date = inject('start_date');

        // 日付書式チェック
        let checkDate = async (_rule, value) => {
            if (value) {
                // NOP、ダミーチェック
                // valueが常にundefinedとなるが、関数内で使用しない場合にLintでエラーとなるため
            }

            // YYYYMMDDを許可する
            var strDate = start_date.value;
            if (!strDate.match(/^\d{4}\d{2}\d{2}$/)) {
                return Promise.reject('');
            }

            return Promise.resolve();
        };
        return {
            start_date,
            checkDate,
        };
    },
    methods:
    {
        clear() {
            this.start_date = ''
        },
    },
},
);
</script>