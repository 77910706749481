<template>
    <a-cascader :options="options" placeholder="Please select" @change="onChange" style="width: 110px" v-model:value="selected_value"/>
</template>

<script>
import { defineComponent, ref, inject } from 'vue';

const options = [
    {
        value: 0, label: 'Hot',
    },
    {
        value: 1, label: 'Cold',
    },
];

export default defineComponent({
    methods: {
        onChange(value) {
            this.data_type = value;
        },
        clear() {
            this.data_type = null;
            this.selected_value = '';
        }
    },
    setup() {
        // 上位コンポーネントから共有された変数
        const data_type = inject('data_type')
        return {
            value: ref([]),
            options,
            data_type,
        };
    },
    data() {
        return {
            selected_value: []
        };
    },
},
);

</script>
