import { createApp } from 'vue'
import { Button, Input, Menu, Radio, Table, Drawer, Space, Cascader, Form, Layout, Popconfirm, Typography, notification, message, Tree } from 'ant-design-vue';
import App from './App.vue'
import router from './router';

import awss3config from './aws-s3-config.json';

const app = createApp(App);
app.config.productionTip = false;
app.use(router);

/* Automatically register components under Button, such as Button.Group */
app.use(Button);
// app.use(DatePicker);
app.use(Input);
app.use(Menu);
app.use(Radio);
app.use(Table);
app.use(Drawer);
app.use(Space);
app.use(Cascader);
app.use(Form);
app.use(Layout);
app.use(Popconfirm);
app.use(Typography);
app.use(notification);  // notificationはimportも全て小文字にしないと動作しない
app.use(Tree);

// Amplify Configure
import { Amplify, Storage } from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'ap-northeast-1:a88b694d-00ec-4a20-8adf-20dbb1a7ae52',

        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_dlwRW3foZ',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2i42ipj525p31ke6btiuhif421',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
        // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
        signUpVerificationMethod: 'code', // 'code' | 'link'

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        //     // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        //     // OPTIONAL - Cookie path
        //     path: '/',
        //     // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        //     // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        //     sameSite: 'strict' | 'lax',
        //     // OPTIONAL - Cookie secure flag
        //     // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true,
        // },

        // OPTIONAL - customized storage object
        // storage: MyStorage,

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'your_cognito_domain',
        //     scope: [
        //         'phone',
        //         'email',
        //         'profile',
        //         'openid',
        //         'aws.cognito.signin.user.admin',
        //     ],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/',
        //     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        // },
    },
    AWSS3: awss3config,
});

// Amplify UI Vue
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
app.use(AmplifyVue);
app.use(Storage);

app.config.globalProperties.$message = message;

app.mount('#app');