<template>
  <!-- データ収集期間 -->
  <a-typography-text>①データ収集期間</a-typography-text>
  <CollectionPeriodPicker />

  <!-- 向け先、機種 -->
  <a-typography-text>②向け先、③機種</a-typography-text><br>
  <!-- <CustomerAndModelChooser /> -->
  <CustomerAndModelCascader />
  <br><br>
  <!-- 目的、機能 -->
  <a-typography-text>④目的・機能</a-typography-text>
  <PurposeChooser />

  <br>
  <!-- フリーワード -->
    <a-typography-text>⑤フリーワード（旧パス等）</a-typography-text>
    <br>
    <a-input v-model:value="freeword" allowClear placeholder="自由入力" style="width: 30%" />
  <br>
</template>

<script>
import CollectionPeriodPicker from '@/components/parts/CollectionPeriodPicker.vue';
// import CustomerAndModelChooser from '@/components/parts/CustomerAndModelChooser.vue';
import CustomerAndModelCascader from '@/components/parts/CustomerAndModelCascader.vue';
import PurposeChooser from '@/components/parts/PurposeChooser.vue';
import { inject } from 'vue';

export default {
  components: {
    CollectionPeriodPicker,
    // CustomerAndModelChooser,
    CustomerAndModelCascader,
    PurposeChooser,
  },
  setup() {
    // 上位コンポーネントから共有された変数
    const freeword = inject('freeword')
    return {
      freeword,
    };
  },
}

</script>